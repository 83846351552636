<template>
  <v-app-bar
    height="150"
  >
      <v-img
        src="@/assets/logo.png"
        width="100"
        height="100"
        contain
      />
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Header'
  }
</script>
